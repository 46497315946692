@import url("https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-sora: "Sora", sans-serif;
  --font-code: "Source Code Pro", monospace;
  --font-grotesk: "Space Grotesk", sans-serif;
  --font-roboto: "Roboto", sans-serif;

  /* color-scheme: dark; */
  /* background-color: slategray; */
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

html {
  @apply bg-white text-blue-950;
}

body {
  /* font-family: var(--font-roboto); */
  min-height: 100vh;
}

.button {
  @apply text-xs font-bold uppercase tracking-wider transition-colors hover:text-blue-600;
}

.button-primary {
  @apply mt-2 px-4 py-1 text-white bg-blue-950  rounded;
}

a:hover {
  @apply transition-colors hover:text-blue-600;
}

a.top-rec {
  @apply transition-colors text-blue-950 text-[2.25rem] sm:text-[2.5rem];
}

a.top-rec:hover {
  @apply hover:text-blue-600;
}

.arrow-btn {
  @apply px-2 py-0.5 text-white bg-blue-950 rounded-full size-8;
}

.arrow-btn:hover {
  @apply text-black bg-white border border-solid border-blue-950;
}

.h1 {
  @apply font-bold text-[3rem] leading-[3rem] md:text-[3rem] md:leading-[3.5rem] lg:text-[3.5rem] lg:leading-[3.5rem] xl:text-[3.5rem] xl:leading-[4rem];
}

.h2 {
  @apply text-[2rem] leading-[2.5rem] font-bold md:text-[2rem] md:leading-[2.5rem] lg:text-[2.5rem] lg:leading-[3.5rem] xl:text-[3rem] xl:leading-tight;
}

.h3 {
  @apply text-[1.5rem] leading-[2.25rem] md:text-[1.75rem] md:leading-[2.5rem] lg:text-[2rem] lg:leading-[2.75rem] xl:text-[2.5rem] xl:leading-[3.5rem];
}

.h4 {
  @apply text-[1.25rem] leading-[2rem] md:text-[1.5rem] md:leading-[2.25rem] lg:text-[1.75rem] lg:leading-[2.5rem] xl:text-[2rem] xl:leading-[2.75rem] font-bold;
}

.h5 {
  @apply text-[1rem] leading-[1.75rem] md:text-[1rem] md:leading-[2rem] lg:text-[1.25rem] lg:leading-[2rem] xl:text-[1.5rem] xl:leading-[2.5rem];
}

.container {
  @apply max-w-[77.5rem] mx-auto px-5 md:px-10 lg:px-[3.75rem] xl:max-w-[87.5rem] p-2 md:p-4;
}

.thead {
  @apply font-bold text-[1.25rem] border border-blue-200;
}

.caption {
  @apply text-sm;
}

.login-popup {
  @apply opacity-0;
}

.login-popup.show {
  @apply opacity-100 duration-300 ease-in-out fixed top-20 left-[84%] bg-blue-950 text-white px-4 py-2 rounded;
}
